import React from 'react'

import Layout from '../components/Layout'

import { pageLink, conversionId, googleConversion } from '../utils'

import './creators.scss';

const Box = ({title, text}) => (
  <div className="box">
    <h2 className="box__title">{title}</h2>
    <p className="box__text">{text}</p>
  </div>
)

const boxes = [
  {
    title: "Connect",
    text: "to a rapidly growing ecosystem of users and consumers"
  },
  {
    title: "Drive",
    text: "traffic to your social profiles, website or virtual world"
  },
  {
    title: "Collaborate",
    text: "with other creators and brands"
  },
  {
    title: "Find",
    text: "new consumers for your products or services"
  },
]

const CreatorsPage = () => {
  return (
    <Layout
      title="Creators | Teleportation in the Metaverse | MHTTP"
      pageClassName="page-creators"
      description="MHTTP empowers you to explore the endless possibilities of the Metaverse and is the most user-friendly Metaverse platform. Create portals and travel to any virtual world"
    >
      <section className="page-creators__hero" id="why">
        <div className="page-creators__hero__container">
          <h2 className="page-title">The Metaverse is for EVERYONE</h2>
          <p>Digital creators, online sellers, small businesses, anyone - we want to help you establish your presence in the Metaverse</p>
          <a 
            className="button" 
            href={pageLink.portal.create} 
            target="_blank" 
            rel="noreferrer" 
            aria-label="build a portal"
            onClick={() => googleConversion(conversionId.buildAPortal)}
          >
            Build a Portal
          </a>
        </div>
      </section>
      <section className="page-creators__why">
        <div className="page-creators__why__container">
          <h2>Why be a part of the Metaverse?</h2>
          <div className="page-creators__why__container__boxes">
            {boxes.map(eachBox => (
              <Box
                key={eachBox.title}
                title={eachBox.title}
                text={eachBox.text}
              />
            ))}
          </div>
        </div>
      </section>
      <section className="page-creators__what" id="what">
        <div className="page-creators__what__container">
          <h2>What is a Portal?</h2>
          <p>A way of traveling between worlds in the Metaverse</p>
          <p>A visual way of linking virtual worlds and experiences</p>
          <p>A fully customizable ‘link’ you can build for the Metaverse</p>
        </div>
      </section>
      <section className="page-creators__cta" id="build">
        <div className="page-creators__cta__container">
          <h2>Ready to Start Creating?</h2>
          <p>Making a portal is fast and easy. No coding required.</p>
          <a 
            className="button" 
            href={pageLink.portal.create} 
            target="_blank" 
            rel="noreferrer" 
            aria-label="get started"
          >
            Get Started
          </a>
        </div>
      </section>
    </Layout>
  )
}

export default CreatorsPage